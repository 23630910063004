<template>
  <div class="manga main-body">
    <div id="vol1"></div>
    <h2 class="top-title">METABA MANGA Vol.1</h2>

    <div class="manga-container">
      <v-img class="manga-page" src="/images/manga/fumm/00/001.jpg" />
      <v-img class="manga-page" src="/images/manga/fumm/00/002.jpg" />
      <v-img class="manga-page" src="/images/manga/fumm/00/003.jpg" />
      <v-img class="manga-page" src="/images/manga/fumm/00/004.jpg" />
    </div>

    <div class="creator">
      <div class="subtitle2">作者</div>
      <a href="https://twitter.com/tsunoda_fumm" target="_blank">
        <v-avatar size="36">
          <img
            src="/images/manga/fumm/fumm.jpg"
            alt="つのだふむ"
          >
        </v-avatar>
        <strong style="margin-left: 10px">つのだふむ</strong>
      </a>
      <div class="caption">
        マンガ家。「リアル・ユー」（LINEマンガ、cakes/電子書籍1-5巻）
        「REACH無限の起業家」（NewsPicks）/「ブランディングマン」「問いのりさこ」（Twitter）
        まんが舞台袖noteマガジン→「シアターふむ」 コルク所属 コザクラインコ飼ってます。
        <a href="https://twitter.com/tsunoda_fumm" target="_blank">@tsunoda_fumm</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.manga {
  padding-top: 40px;
}

.caption {
  margin-top: 10px;
}

.creator {
  margin-bottom: 50px;
  max-width: 500px;
  margin: 0 auto;
}

.manga-container {
  margin: 0 auto;
  max-width: 500px;

  .manga-page {
    margin: 20px 10px;
  }
}
</style>
