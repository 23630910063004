<template>
  <div class="main-body">
    <h2 class="top-title">{{$t('tosBody.title')}}</h2>
    <div>
      {{$t('tosBody.abst')}}
    </div>

    <div class="section">
      <h3>{{$t('tosBody.1.title')}}</h3>
      <div>
        {{$t('tosBody.1.text')}}
      </div>
      <ol class="aol" type="a">
        <li class="aol">{{$t('tosBody.1.a')}}</li>
        <li class="aol">{{$t('tosBody.1.b')}}</li>
        <li class="aol">{{$t('tosBody.1.c')}}</li>
        <li class="aol">{{$t('tosBody.1.d')}}</li>
        <li class="aol">{{$t('tosBody.1.e')}}</li>
        <li class="aol">{{$t('tosBody.1.f')}}</li>
        <li class="aol">{{$t('tosBody.1.g')}}</li>
      </ol>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.2.title')}}</h3>
      <div>
        {{$t('tosBody.2.text')}}
      </div>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.3.title')}}</h3>
      <h4>{{$t('tosBody.3.a.title')}}</h4>
      <div>
        {{$t('tosBody.3.a.text')}}
      </div>
      <h4>{{$t('tosBody.3.b.title')}}</h4>
      <div>
        {{$t('tosBody.3.b.text')}}
      </div>
      <ol class="iol" type="i">
        <li class="iol">{{$t('tosBody.3.b.1')}}</li>
        <li class="iol">{{$t('tosBody.3.b.2')}}</li>
        <li class="iol">{{$t('tosBody.3.b.3')}}</li>
        <li class="iol">{{$t('tosBody.3.b.4')}}</li>
        <li class="iol">{{$t('tosBody.3.b.5')}}</li>
        <li class="iol">{{$t('tosBody.3.b.6')}}</li>
      </ol>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.4.title')}}</h3>
      <ol class="aol" type="a">
        <li class="aol">
          {{$t('tosBody.4.a.text')}}
        </li>
        <ol class="iol" type="i">
          <li class="iol">{{$t('tosBody.4.a.1')}}</li>
          <li class="iol">{{$t('tosBody.4.a.2')}}</li>
          <li class="iol">{{$t('tosBody.4.a.3')}}</li>
          <li class="iol">{{$t('tosBody.4.a.4')}}</li>
          <li class="iol">{{$t('tosBody.4.a.5')}}</li>
          <li class="iol">{{$t('tosBody.4.a.6')}}</li>
          <li class="iol">{{$t('tosBody.4.a.7')}}</li>
          <li class="iol">{{$t('tosBody.4.a.8')}}</li>
          <li class="iol">{{$t('tosBody.4.a.9')}}</li>
          <li class="iol">{{$t('tosBody.4.a.10')}}</li>
        </ol>
        <li class="aol">{{$t('tosBody.4.b.text')}}</li>
      </ol>
    </div>
    
    <div class="section">
      <h3>{{$t('tosBody.5.title')}}</h3>
      <ol class="aol" type="a">
        <li class="aol">
          {{$t('tosBody.5.a')}}
        </li>
        <li class="aol">
          {{$t('tosBody.5.b.text')}}
        </li>
        <ol class="iol" type="i">
          <li class="iol">{{$t('tosBody.5.b.1')}}</li>
          <li class="iol">{{$t('tosBody.5.b.2')}}</li>
          <li class="iol">{{$t('tosBody.5.b.3')}}</li>
          <li class="iol">{{$t('tosBody.5.b.4')}}</li>
        </ol>
      </ol>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.6.title')}}</h3>
      <div>
        {{$t('tosBody.6.text')}}
      </div>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.7.title')}}</h3>
      <ol class="aol" type="a">
        <li class="aol">{{$t('tosBody.7.a')}}</li>
        <li class="aol">{{$t('tosBody.7.b')}}</li>
        <li class="aol">{{$t('tosBody.7.c')}}</li>
        <li class="aol">{{$t('tosBody.7.d')}}</li>
        <li class="aol">{{$t('tosBody.7.e')}}</li>
      </ol>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.8.title')}}</h3>
      <div>
        {{$t('tosBody.8.text')}}
      </div>
      <ol class="aol" type="a">
        <li class="aol">{{$t('tosBody.8.a')}}</li>
        <li class="aol">{{$t('tosBody.8.b')}}</li>
        <li class="aol">{{$t('tosBody.8.c')}}</li>
        <li class="aol">{{$t('tosBody.8.d')}}</li>
        <li class="aol">{{$t('tosBody.8.e')}}</li>
        <li class="aol">{{$t('tosBody.8.f')}}</li>
        <li class="aol">{{$t('tosBody.8.g')}}</li>
        <li class="aol">{{$t('tosBody.8.h')}}</li>
        <li class="aol">{{$t('tosBody.8.i')}}</li>
        <li class="aol">{{$t('tosBody.8.j')}}</li>
        <li class="aol">{{$t('tosBody.8.k')}}</li>
        <li class="aol">{{$t('tosBody.8.l')}}</li>
        <li class="aol">{{$t('tosBody.8.m')}}</li>
      </ol>
      <div>
        {{$t('tosBody.8.foot')}}
      </div>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.9.title')}}</h3>
      <div>
        {{$t('tosBody.9.text')}}
      </div>
      <ol class="aol" type="a">
        <li class="aol">{{$t('tosBody.9.a')}}</li>
        <li class="aol">{{$t('tosBody.9.b')}}</li>
      </ol>
      <div>
        {{$t('tosBody.9.foot')}}
      </div>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.10.title')}}</h3>
      <p>
        {{$t('tosBody.10.text')}}
      </p>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.11.title')}}</h3>
      <p>
        {{$t('tosBody.11.text')}}
      </p>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.12.title')}}</h3>
      <p>
        {{$t('tosBody.12.text')}}
      </p>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.13.title')}}</h3>
      <p>
        {{$t('tosBody.13.text')}}
      </p>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.14.title')}}</h3>
      <p>
        {{$t('tosBody.14.text')}}
      </p>
    </div>

    <div class="section">
      <h3>{{$t('tosBody.15.title')}}</h3>
      <p>
        {{$t('tosBody.15.text')}}
      </p>
    </div>

    <div class="createdat-section">
      {{$t('tosBody.createdat')}}
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.main-body {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  padding-top: 40px;

  .section {
    margin: 20px 0;
  }

  .createdat-section {
    margin: 40px 0 100px 0;
  }

  ol.aol {
    li.aol {
      list-style-type: none;
      counter-increment: cnta;
      position: relative;
      margin-left: 5px;

      &:before {
        content: "(" counter(cnta, lower-alpha) ")";
        text-align: right;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }
  }

  ol.iol {
    li.iol {
      list-style-type: none;
      counter-increment: cnti;
      position: relative;
      margin-left: 10px;

      &:before {
        content: "(" counter(cnti, lower-roman) ")";
        text-align: right;
        position: absolute;
        top: 0;
        left: -30px;
      }
    }
  }
}
</style>
