<template>
  <v-app id="app">
    <v-app-bar v-if="!isGrid && !isHome" app>
      <localized-link :to="{name: 'Home'}">
        <v-toolbar-title>
          <v-img
            :src="require('./assets/logo-text.png')"
            class="logo"
            contain
            width="100"
          />
        </v-toolbar-title>
      </localized-link>

      <language-switcher class="lang-selector" v-slot="{ links }">
        <router-link class="flag-container" :to="link.url" v-for="link in links" :key="link.langIndex">
          <img class="flag" :src="'/images/lang/' + link.langIndex + '.png'" />
        </router-link>
      </language-switcher>
              
      <v-spacer></v-spacer>
      <v-btn
        v-if="!accountInfo"
        class="wallet-button"
        depressed
        color="primary"
        @click="connectWallet"
      >
        {{$t('connectToWallet')}}
      </v-btn>
      <v-btn
        v-else
        class="wallet-button"
        depressed
        color="primary"
      >
        <localized-link class="white--text" :to="{name: 'Grid'}">{{$t('goToGrid')}}</localized-link>
      </v-btn>
    </v-app-bar>

    <v-main ref="main" class="main">
      <v-container fluid :class="'no-padding-container'" style="height: 100%; min-height: 100%">
        <router-view class="route-view" @connect-wallet="connectWallet" @get-account-info="getAccountInfo" :account-info="accountInfo" />

        <v-footer bottom absolute padless>
          <v-row
            justify="center" no-gutters
          >
            <v-btn
              v-for="link in links"
              :key="link.target"
              text
              rounded
              class="my-2"
            >
              <a v-if="link.targetBlank" :href="link.url">{{$t(link.i18nName)}}</a>
              <localized-link v-else :to="{name: link.target}">{{$t(link.i18nName)}}</localized-link>
            </v-btn>

            <v-col
              class="py-4 text-center"
              cols="12"
            >
              <language-switcher class="lang-selector" v-slot="{ links }">
                <router-link class="flag-container" :to="link.url" v-for="link in links" :key="link.langIndex">
                  <img class="flag" :src="'/images/lang/' + link.langIndex + '.png'" />
                </router-link>
              </language-switcher>
            </v-col>

            <v-col
              class="py-4 text-center"
              cols="12"
            >
              {{ new Date().getFullYear() }} — <strong><a href="https://corkagency.com/" target="_blank">CORK, Inc.</a></strong>
            </v-col>
          </v-row>
        </v-footer>
      </v-container>
    </v-main>
    <WalletInstallDialog v-if="isOpenWalletDialog" :onClose="onCloseWalletDialog" />
    
    <div id="output" v-html="debugOutput"></div>

    <v-snackbar
      v-model="isShowSnackbar"
      timeout="5000"
      multi-line
    >
      <h4 v-if="snackBarTitle">{{snackBarTitle}}</h4>
      {{snackBarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="isShowSnackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Free mint button -->
    <transition name="sliding">
      <router-link v-if="isShowFreeMintButton && !isFreeMint" :to="{name: 'Mint'}" id="free-mint">
        <v-img src="/images/mint/free-mint.png" :height="isSmartphone ? 80 : 120" :width="isSmartphone ? 211 : 317" />
      </router-link>
    </transition>
  </v-app>
</template>

<script>
import web3Utils from './web3utils'
import utils from './utils'
import WalletInstallDialog from './components/WalletInstallDialog.vue'

export default ({
  data() {
    return {
      links: [
        {
          targetBlank: false,
          target: 'Home',
          i18nName: 'home'
        },
        {
          targetBlank: false,
          target: 'Collections',
          i18nName: 'collections'
        },
        {
          targetBlank: false,
          target: 'News',
          i18nName: 'news'
        },
        {
          targetBlank: false,
          target: 'Manga',
          i18nName: 'manga'
        },
        {
          targetBlank: false,
          target: 'NftTos',
          i18nName: 'nftTos'
        },
        // {
        //   targetBlank: true,
        //   url: 'https://corkshop.jp/fs/corkinc/PrivacyPolicy.html',
        //   i18nName: 'privacyPolicy'
        // },
        {
          targetBlank: false,
          target: 'PrivacyPolicy',
          i18nName: 'privacyPolicy'
        },
        {
          targetBlank: false,
          target: 'CommerceLaw',
          i18nName: 'commerceLaw'
        }
      ],
      isLoggedIn: false,
      accountInfo: null,
      isOpenWalletDialog: false,
      intervalId: null,
      debugOutput: '',
      debugFlag: false,
      isShowSnackbar: false,
      snackBarTitle: null,
      snackBarText: '',
      isShowFreeMintButton: true,
      freeMintIntervalId: null,
      isSmartphone: utils.isSmartphone(),
    }
  },

  computed: {
    isGrid() {
      return this.$route.path === '/grid/inventory/' || this.$route.path === '/grid/inventory' || this.$route.path === '/ja/grid/inventory' || this.$route.path === '/en/grid/inventory'
    },

    isHome() {
      return this.$route.path === '/' || this.$route.path === '/en/' || this.$route.path === '/ja/'
    },

    isFreeMint() {
      return this.$route.path.indexOf('mint') >= 0
    }
  },

  async mounted() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function () {
        location.reload()
      })
      window.ethereum.on('chainChanged', function () {
        location.reload()
      })
    }

    const web3 = web3Utils.getWeb3()
    if (web3) {
      const accounts = await web3.eth.getAccounts()
      if (accounts.length == 0) {
        // console.log('User is not logged in to MetaMask')
        this.isLoggedIn = false
        ////////show(connectButton)
      }
      else {
        // console.log('User is logged in to MetaMask')
        this.isLoggedIn = true

        this.getAccountInfo(accounts[0])
      }

      this.intervalId = setInterval(this.getAccountInfo, 15000)
    } else {
      /////////show(connectButton)
    }

    // Add scroll handler
    this.freeMintIntervalId = setInterval(this.onScroll, 500)
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = void 0
    }

    // Remove scroll handler
    if (this.freeMintIntervalId) {
      clearInterval(this.freeMintIntervalId)
    }
  },

  methods: {
    async connectWallet() {
      const web3 = web3Utils.getWeb3()
      if (web3) {
        // Connect to a wellet
        this.debug('connectWallet')
        if (web3Utils.isMetamask()) {
          // Change Polygon main net if different network
          const chainIdString = await web3Utils.getChainIdString()
          this.debug('chainIdString: ' + chainIdString)
          if (chainIdString !== web3Utils.polygonMainnetChainIdString) {
            try {
              //alert('wallet_switchEthereumChain')
              this.debug('wallet_switchEthereumChain')
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3Utils.polygonMainnetChainIdHexString }]
              })
              //alert('Done wallet_switchEthereumChain')
              this.debug('Done wallet_switchEthereumChain')
              setTimeout(() => {
                location.reload()
              }, 500)
            } catch (switchError) {
              this.debug('switchError' + JSON.stringify(switchError))
              //alert('switchError' + JSON.stringify(switchError))
              if (switchError.code === 4902 || switchError.message.indexOf('Unrecognized chain ID') >= 0) {
                try {
                  await this.addEthereumChain()
                  this.debug('DONE addEthereumChain')

                  // Check chain ID
                  setTimeout(async () => {
                    const chainIdString2 = await web3Utils.getChainIdString()
                    if (chainIdString2 !== web3Utils.polygonMainnetChainIdString) {
                      // Still wrong chain ID
                      // retry addEthereumChain
                      await this.addEthereumChain()
                    }
                  }, 600)
                } catch (addError) {
                  //alert('ADD_ERROR')
                  this.debug('ADD_ERROR' + JSON.stringify(addError))
                  console.log('####ADD_ERROR', addError)

                  // Retry
                  setTimeout(async () => {
                    this.debug('Retry addEthereumChain')
                    await this.addEthereumChain()
                  }, 500)
                }
              }
            }
          } else if (!chainIdString) {
            // Try reload
            location.reload()
          }

          // Metamask with PC
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
          //console.log(accounts)
          //alert(JSON.stringify(accounts));
          //alert(accounts[0]);
          this.getAccountInfo(accounts[0])
        }
        else {
          this.showInstallWalletDialog()

          // Non metamask
          // console.log('Non Metamask')
          // if (isSmartphone) {
          //   showDebug('Non metamask isSmartphone')
          //   // Show Metamask & WalletConnect dialog
          //   // Metamask -> https://metamask.app.link/dapp/URL
          //   // WalletConnect -> ??
          // } else {
          //   // Enable ethereum
          //   await getWalletConnectProvider().enable()
          //   // await window.ethereum.enable()
          // }
        }
      } else {
        // TODO: Not supported alert
        this.showInstallWalletDialog()
      }
    },

    showInstallWalletDialog() {
      this.isOpenWalletDialog = true
    },

    onCloseWalletDialog() {
      this.isOpenWalletDialog = false
    },

    async addEthereumChain() {
      this.debug('wallet_addEthereumChain')
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: web3Utils.polygonMainnetChainIdHexString,
          chainName: 'Polygon PoS Mainnet',
          nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18
          },
          rpcUrls: ['https://polygon-rpc.com'],
          blockExplorerUrls: ['https://polygonscan.com/']
        }]
      })

      setTimeout(() => {
        this.debug('Reloading...')
        location.reload()
      }, 500)
    },

    async getAccountInfo(account) {
      this.debug('getAccountInfo: ' + account)
      if (!account && this.accountInfo) {
        account = this.accountInfo.address
      }

      if (!account) {
        return
      }
      
      const web3 = web3Utils.getWeb3()
      const chainIdString = await web3Utils.getChainIdString()
      this.debug('chainIdString: ' + chainIdString)
      if (chainIdString === web3Utils.polygonMainnetChainIdString || chainIdString === web3Utils.polygonMainnetChainIdHexString) {
        try {
          this.debug('Before getBalance: ' + account)
          const balance = await web3.eth.getBalance(account)
          this.debug('After getBalance: ' + balance)
          const matic = balance / 1000000000000000000

          this.accountInfo = {
            address: account,
            matic: matic.toFixed(4)
          }

          this.debug(JSON.stringify(this.accountInfo))
        } catch (e) {
          this.debug(JSON.stringify(e))
        }
      }
      else if (!chainIdString) {
        this.showSnackbar('Please refresh your browser', 'Failed Web3 Initialization')
      }
    },

    debug(text) {
      if (this.debugFlag) {
        this.debugOutput += text + '<br>'
      }
    },

    showSnackbar(text, title) {
      this.snackBarTitle = title
      this.snackBarText = text
      this.isShowSnackbar = true
    },

    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition < 0) {
        return
      }

      // Here we determine whether we need to show or hide the navbar
      this.isShowFreeMintButton = (currentScrollPosition + window.outerHeight < (this.$refs.main.$el.offsetHeight - 100)) || this.isGrid
      // console.log(this.isShowFreeMintButton, (currentScrollPosition + window.outerHeight < (this.$refs.main.$el.offsetHeight - 100)), !this.isGrid)
    }
  },

  components: {
    WalletInstallDialog
  }
})
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .lang-selector {
    margin-left: 8px;

    .flag {
      width: 30px;
      margin: 3px;
    }
  }

  .no-padding-container {
    padding: 0 !important;
  }

  .main-body {
    padding-bottom: 250px;
  }

  .home {
    .main-body {
    padding-bottom: 0;
  }
  }

  footer {
    background-color: #2c3e50 !important;
    color: #FFF;

    a {
      font-weight: bold;
      color: #FFF;
      text-decoration: none;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  #output {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100000;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    color: #FFF;
    font-size: 12px;
  }

  .wallet-button {
    border-radius: 20px !important;
  }

  #free-mint {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
  }

  .sliding-enter-active, .sliding-leave-active {
    will-change: opacity;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .sliding-enter, .sliding-leave-to {
    opacity: 0;
  }
}
</style>
