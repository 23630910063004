import Vue from 'vue'
import LangRouter from 'vue-lang-router'
import Home from '../views/Home.vue'
import NftTos from '../views/NftTos.vue'
import CommerceLaw from '../views/CommerceLaw.vue'
import Manga from '../views/Manga.vue'
import Inventory from '../views/Inventory.vue'
import Mint from '../views/Mint.vue'
import News from '../views/News.vue'
import Collections from '../views/Collections.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import translations from '../lang/translations'

Vue.use(LangRouter, {
	defaultLanguage: 'ja',
	translations,
})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nft_tos',
    name: 'NftTos',
    component: NftTos
  },
  {
    path: '/commerce_law',
    name: 'CommerceLaw',
    component: CommerceLaw
  },
  {
    path: '/manga',
    name: 'Manga',
    component: Manga
  },
  {
    path: '/grid',
    name: 'Grid',
    redirect: '/grid/inventory'
  },
  {
    path: '/grid/dressup',
    redirect: '/grid/inventory'
  },
  {
    path: '/grid/inventory',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/mint',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return{
      selector: to.hash
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
