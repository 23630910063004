<template>
  <div id="grid">
    <router-link :to="{name: 'Home'}"><img id="logo" src="/images/logo/logo-text.png"></router-link>
    <v-btn v-if="!accountInfo" small rounded id="connect-button" color="primary" @click="onClickConnectButton">
      Connect wallet
    </v-btn>
    <div v-else class="account" @click="onClickAccountInfo">
      <div class="account-address">{{accountInfo && accountInfo.address}}</div>
      <div class="account-matic">{{accountInfo ? accountInfo.matic : '-'}} MATIC</div>
      <div v-if="isShowAccountCopyToolchip" class="account-copy">Copied your address!</div>
    </div>

    <v-container class="main lighten-5">
      <h2 style="margin-bottom: 10px">
        Avatars
        
        <v-btn
          :style="{visibility: !isLoadingAvatars ? 'visible' : 'hidden'}"
          color="white"
          icon
          small
          @click="onClickReloadAvatars"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </h2>
      <!--div class="avatar-warning">
        <div class="warning-en">Currently, avatar thumbnails are not updated when you update your avatars</div>
        <div class="warning-ja">現在アバターのきせかえを行ってもアバターのサムネイルは変更されません。いま開発中なのでもう少し待ってね！</div>
      </div-->
      <v-row>
        <template v-if="avatars && avatars.length > 0">
          <v-col
            v-for="avatar in avatars"
            :key="avatar.name"
            cols="12"
            sm="3"
          >
            <v-card
              class="mx-auto"
              outlined
              ripple
              @click="onClickAvatar(avatar)"
            >
              <v-img
                class="thumbnail"
                :aspect-ratio="1"
                :src="avatar.image + (avatar.metaba && avatar.metaba.hash ? ('?' + avatar.metaba.hash) : '')"
              ></v-img>
              <div class="avatar-updating" v-if="avatar.metaba && avatar.metaba.avatarDataUpdating">
                <span class="avatar-updating-text">UPDATING...</span>
                <v-progress-circular
                  class="avatar-updating-progress"
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
              <v-card-text class="nft-title">
                {{avatar.name}}
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <v-col v-else>
          {{avatarEmptyText}}
        </v-col>
      </v-row>

      <h2 style="margin-top: 50px; margin-bottom: 10px">
        Items

        <v-btn
          :style="{visibility: !isLoadingItems ? 'visible' : 'hidden'}"
          color="white"
          icon
          small
          @click="onClickReloadItems"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </h2>
      <v-row>
        <template v-if="items && items.length > 0">
          <v-col
            v-for="item in items"
            :key="item.name"
            cols="12"
            sm="3"
          >
            <v-card
              class="mx-auto"
              outlined
              ripple
              @click="onClickItem(item)"
            >
              <v-img
                class="thumbnail"
                :aspect-ratio="1"
                :src="item.image"
              ></v-img>
              <v-card-text class="nft-title">
                {{item.name}}
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <v-col v-else>
          {{itemEmptyText}}
        </v-col>
      </v-row>
      
      <v-btn v-if="!accountInfo" style="margin-top: 60px" x-large rounded color="primary" @click="onClickConnectButton">
        Connect wallet
      </v-btn>
    </v-container>

    <v-dialog
      v-if="selectedAvatar"
      class="avatar-item-dialog"
      v-model="avatarDialog"
      max-width="350"
      overlay-opacity="0.9"
    >
      <v-card 
        class="avatar-dialog-body mx-auto" style="text-align: left">
        <v-img
          :src="selectedAvatar.image + (selectedAvatar.metaba && selectedAvatar.metaba.hash ? ('?' + selectedAvatar.metaba.hash) : '')"
          aspect-ratio="1.0"
        ></v-img>
        <div class="avatar-updating" v-if="selectedAvatar.metaba && selectedAvatar.metaba.avatarDataUpdating">
          <span class="avatar-updating-text">UPDATING...</span>
          <v-progress-circular
            class="avatar-updating-progress"
            indeterminate
            color="green"
          ></v-progress-circular>
        </div>

        <v-btn
          color="white"
          icon
          style="position: absolute; top: 10px; right: 10px"
          @click.stop="avatarDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>{{selectedAvatar.name}}</v-card-title>

        <v-card-text>
          <v-btn
            color="info"
            small
            outlined
            :href="'https://opensea.io/assets/matic/' + selectedAvatar.contractAddress + '/' + selectedAvatar.id"
            target="blank_"
            style="margin-bottom: 10px"
          >
            Opean Sea
          </v-btn><br>

          <v-btn
            color="primary"
            small
            outlined
            :href="`https://contents.metabaworld.com/${selectedAvatar.collectionId}/avatars/${selectedAvatar.id}/${getMd5('haiku' + selectedAvatar.collectionId + selectedAvatar.id)}.vrm`"
            target="blank_"
            style="margin-bottom: 10px"
          >
            Download VRM
          </v-btn>
          <template v-if="selectedAvatar.metaba && selectedAvatar.metaba.avatarDataUpdating">
            <span class="updating-vrm">UPDATING</span>
          </template>

          <br>

          <v-btn
            v-if="selectedAvatar && selectedAvatar.metaba && selectedAvatar.metaba.specialVideoPath"
            color="red"
            small
            outlined
            target="blank_"
            style="margin-bottom: 10px"
            @click="onClickPlaySpecialVideo"
          >
            Play Special Video
          </v-btn>
          
        </v-card-text>

        <v-card-text style="padding-bottom: 0; color: #000 !important">
          <div class="text-subtitle-1">Avatar items</div>
        </v-card-text>
          
        <v-container class="lighten-5">
          <v-row dense>
            <template v-if="selectedAvatarItems && selectedAvatarItems.length > 0">
              <v-col v-for="item in selectedAvatarItems" :key="item.id" cols="6" sm="3">
                <v-card ripple hover>
                  <v-img
                    :src="item.image"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="onClickItem(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </template>
            <v-col v-else cols="12" sm="12">
              <div style="margin-left: 20px">
                {{ isLoadingAvatarItems ? 'Loading' : 'None' }}
              </div>
            </v-col>
          </v-row>
        </v-container>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            color="primary"
            :loading="isLoadingAvatarItems"
            :disabled="isLoadingAvatarItems || (selectedAvatar && selectedAvatar.metaba && selectedAvatar.metaba.disableWearItems)"
            @click="onClickOpenUnity"
          >
            Dress Up
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedItem"
      v-model="itemDialog"
      max-width="330"
      overlay-opacity="0.9"
    >
      <v-card 
        class="item-dialog-body mx-auto" style="text-align: left">
        <v-img
          :src="selectedItem.image"
          aspect-ratio="1.0"
        ></v-img>

        <v-btn
          color="white"
          icon
          style="position: absolute; top: 10px; right: 10px"
          @click.stop="itemDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>{{selectedItem.name}}</v-card-title>

        <v-card-text>
          <v-btn
            color="info"
            small
            outlined
            :href="'https://opensea.io/assets/matic/' + selectedItem.contractAddress + '/' + selectedItem.id"
            target="blank_"
            style="margin-bottom: 20px"
          >
            Opean Sea
          </v-btn><br>
          <div class="avatar-description" v-html="convertUrlLink(selectedItem.description)"></div>
        </v-card-text>

        <v-divider></v-divider>

        <!--v-card-actions>
          <v-btn
            color="info"
            text
            :href="'https://opensea.io/assets/matic/' + selectedItem.contractAddress + '/' + selectedItem.id"
            target="blank_"
          >
            Opean Sea
          </v-btn>
        </v-card-actions-->
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="transactions"
      v-model="transactionDialog"
      max-width="400"
      persistent
      overlay-opacity="0.9"
    >
      <v-card class="mx-auto" style="text-align: left">
        <v-btn
          icon
          style="position: absolute; top: 10px; right: 10px"
          @click.stop="onClickCloseTransactionDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-title>Transactions</v-card-title>

        <v-divider></v-divider>

        <v-row no-gutters v-for="(transaction, index) of transactions" :key="transaction.transactionId">
          <v-col col="12">
            <v-card tile flat :disabled="transactionIndex !== index" :color="transactionIndex === index ? 'rgba(92, 200, 59, 0.1)' : 'rgba(0, 0, 0, 0.05)'">
              <v-card-text>
                <h4>
                  {{ 
                    'STEP' + (index + 1) + '. ' + (transaction.type === 'add' ? 'Add ' : 'Remove ') +
                    ' following ' + (transaction.batch ? 'items' : 'item') +
                    (transaction.type === 'add' ? ' to ' : ' from ') + 'the avatar'
                  }}
                </h4>
                <v-row dense style="margin-top: 5px">
                  <v-col
                    v-for="item in getImageUrlsFromTransaction(transaction)"
                    :key="item.image"
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-card ripple hover @click="onClickItem(item)">
                      <v-img  :src="item.image" aspect-ratio="1" height="50" class="grey lighten-2">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  style="margin-top: 10px"
                  :color="transaction.done ? 'primary' : 'info'"
                  @click="onClickSendTransaction(transaction, index)"
                  :loading="transaction.loading"
                  :disabled="transaction.loading"
                >
                  {{ transaction.done ? 'Done' : 'Send transaction' }}
                </v-btn>
              </v-card-text>
            </v-card>

            <v-divider></v-divider>

          </v-col>
        </v-row>


        <v-card-actions>
          <v-btn
            v-if="transactionIndex < transactions.length"
            text
            @click.stop="onClickCloseTransactionDialog"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="transactionIndex >= transactions.length"
            color="primary"
            @click.stop="onClickCloseTransactionDialog"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="videoUrl"
      v-model="videoDialog"
      max-width="600"
      overlay-opacity="0.9"
      @click:outside="stopVideo"
    >
      <v-btn
        color="white"
        icon
        style="position: absolute; top: 10px; right: 10px"
        @click.stop="stopVideo"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <video ref="videoContent" :src="videoUrl" controlsList="nodownload" controls autoplay style="max-width: 800px"></video>
    </v-dialog>

    <v-snackbar
      v-model="isShowTransactionError"
      multi-line
      timeout="20000"
    >
      <h4>Failed transaction</h4>
      Please reload this page and retry again!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="onClickReload"
        >
          Reload
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="isShowTransactionSuccess"
      timeout="5000"
    >
      Transaction is successfully completed

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="onClickTxDetail"
        >
          Details
        </v-btn>
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="isShowTransactionSuccess = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <UnityPlayer v-if="isShowUnity" :unity-message="unityMessage"
      @initialized="onInitialized" @save="onSave" @cancel="onCancel" />
  </div>
</template>

<script>
import md5 from 'js-md5'
import UnityPlayer from '../components/UnityPlayer.vue'
import utils from '../utils'
import web3Utils from '../web3utils'
import metabaAvatarData from '../abis/MetabaAvatar'
import metabaItemData from '../abis/MetabaItem'

const pleaseConnectText = 'Please connect your wallet'
const loadingText = 'Loading...'

export default {
  props: {
    accountInfo: Object,
    getAccountInfo: Function,
    connectWallet: Function,
  },

  data() {
    return {
      isShowUnity: false,
      avatars: [],
      items: [],
      selectedAvatar: null,
      selectedItem: null,
      avatarData: null,
      selectedAvatarItems: [],
      unityMessage: null,
      avatarDialog: false,
      itemDialog: false,
      transactionDialog: false,
      videoDialog: false,
      isLoadingAvatarItems: false,
      isLoadingAvatars: false,
      isLoadingItems: false,
      transactions: [],
      transactionIndex: 0,
      isShowTransactionError: false,
      isShowTransactionSuccess: false,
      transactionHash: null,
      isLoadedFirstAvatarsAndItems: false,
      isShowAccountCopyToolchip: false,
      showAccountCopyTimeoutId: null,
      videoUrl: null
    }
  },

  computed: {
    avatarEmptyText() {
      if (this.isLoadingAvatars) {
        return loadingText
      } else {
        if (this.accountInfo) {
          return 'Avatar not found'
        } else {
          return pleaseConnectText
        }
      }
    },

    itemEmptyText() {
      if (this.isLoadingItems) {
        return loadingText
      } else {
        if (this.accountInfo) {
          return 'Item not found'
        } else {
          return pleaseConnectText
        }
      }
    }
  },

  mounted() {
    if (this.accountInfo) {
      this.loadAvatarsAndItems()
    }
  },

  watch: {
    accountInfo(val) {
      if (val && val.address && !this.isLoadedFirstAvatarsAndItems) {        
        this.loadAvatarsAndItems(val.address)
      }
    }
  },

  methods: {
    onClickConnectButton() {
      // console.log(this.accountInfo)
      this.$emit('connect-wallet')
    },

    async onClickAvatar(avatar) {
      this.selectedAvatar = avatar
      //console.log('####################', avatar)

      this.avatarDialog = true
      setTimeout(() => {
        this.resetDialogScrollPostion('avatar')
      }, 10)

      this.isLoadingAvatarItems = true
      await this.generateAvatarData(avatar)
      await this.generateItemData()
      this.isLoadingAvatarItems = false
    },

    onClickOpenUnity() {
      this.avatarDialog = false
      this.isShowUnity = true
    },

    onClickItem(item) {
      // console.log(item)
      if (item && item.image) {
        this.selectedItem = item

        this.itemDialog = true
        setTimeout(() => {
          this.resetDialogScrollPostion('item')
        }, 10)
      }
    },

    async onClickAccountInfo() {
      // TopをRemove
      //const testdata1 = '{"Head":{"PrefabId":"2","TokenId":"","ContractAddress":""},"UpperBody":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"LowerBody":{"PrefabId":"2","TokenId":"5","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Shoes":{"PrefabId":"1","TokenId":"10","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Cap":{"PrefabId":"0","TokenId":"15","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Necklace":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Band":{"PrefabId":"0","TokenId":"20","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Hand":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Pet":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"MethodName":"onClickSave","CallbackGameObjectName":"hogege","MetabaCode":"GERORINPA"}'
      //  Top, BottomをRemove
      //const testdata2 = '{"Head":{"PrefabId":"2","TokenId":"","ContractAddress":""},"UpperBody":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"LowerBody":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Shoes":{"PrefabId":"1","TokenId":"10","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Cap":{"PrefabId":"0","TokenId":"15","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Necklace":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Band":{"PrefabId":"0","TokenId":"20","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Hand":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Pet":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"MethodName":"onClickSave","CallbackGameObjectName":"hogege","MetabaCode":"GERORINPA"}'
      // Top, Bottom アイテム入れ替え
      // const testdata3 = '{"Head":{"PrefabId":"2","TokenId":"","ContractAddress":""},"UpperBody":{"PrefabId":"2","TokenId":"25","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"LowerBody":{"PrefabId":"3","TokenId":"26","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Shoes":{"PrefabId":"2","TokenId":"28","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Cap":{"PrefabId":"0","TokenId":"15","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Necklace":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Band":{"PrefabId":"0","TokenId":"20","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Hand":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"Pet":{"PrefabId":"-1","TokenId":"","ContractAddress":""},"MethodName":"onClickSave","CallbackGameObjectName":"hogege","MetabaCode":"GERORINPA"}'
      
      // this.avatarData = JSON.parse('{"Head":{"PrefabId":"2"},"UpperBody":{"PrefabId":"1","TokenId":"0","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"LowerBody":{"PrefabId":"2","TokenId":"5","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Shoes":{"PrefabId":"1","TokenId":"10","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Cap":{"PrefabId":"0","TokenId":"15","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Band":{"PrefabId":"0","TokenId":"20","ContractAddress":"0x6a984eB160e4796f17b7A8AcF451b2B0f1121370"},"Hand":{"PrefabId":"-1"},"Necklace":{"PrefabId":"-1"},"Pet":{"PrefabId":"-1"}}')

      // this.makeTransactionData(JSON.parse(testdata3))
      // this.showTransactions()

      // アドレスをコピーする
      await navigator.clipboard.writeText(this.accountInfo.address)

      // コピーした旨をツールチップで表示
      this.isShowAccountCopyToolchip = true

      // ツールチップを消す処理
      if (this.showAccountCopyTimeoutId) {
        clearTimeout(this.showAccountCopyTimeoutId)
      }

      this.showAccountCopyTimeoutId = setTimeout(() => {
        this.isShowAccountCopyToolchip = false
        this.showAccountCopyTimeoutId = void 0
      }, 2000)
    },

    onClickReloadAvatars() {
      this.getAvatars(this.accountInfo.address)
    },

    onClickReloadItems() {
      this.getItems(this.accountInfo.address)
    },

    resetDialogScrollPostion(type) {
      const elements = document.getElementsByClassName('v-dialog--active')
      if (!elements || !elements.length) {
        return
      }
      for(const element of elements) {
        const bodyElements = element.getElementsByClassName(type + '-dialog-body')
        if (bodyElements && bodyElements.length > 0) {
          element.scrollTop = 0
        }
      }      
    },

    onInitialized() {
      // console.log('### onInitialized')

      this.unityMessage = {
        gameObjectName: 'MetabaDressup',
        functionName: 'LoadAvatarData',
        data: {
          Avatar: this.avatarData,
          Items: this.itemData
        }
      }
    },

    onSave(data) {
      // console.log('### onClickSave',JSON.stringify(data))
      
      // トランザクションのデータを作って
      this.makeTransactionData(data)

      if (this.transactions && this.transactions.length > 0) {
        // トランザクションを順番に実行していく
        this.showTransactions()
      } else {
        // 変更なしなのでunity消す
        this.isShowUnity = false;
      }
    },

    onCancel() {
      this.isShowUnity = false
    },

    async loadAvatarsAndItems(address) {
      if (!address) {
        address = this.accountInfo.address
      }

      // TODO: 同時並列数を制限したいけど、asyncのparallelがうまくいかない
      this.getAvatars(address)
      this.getItems(address)

      this.isLoadedFirstAvatarsAndItems = true
    },

    async getAvatars(address) {
      const abi = metabaAvatarData.abi
      const web3 = web3Utils.getWeb3()

      this.isLoadingAvatars = true
      
      // 各トークンのメタデータを取得
      this.avatars = []

      for(let contractInfo of web3Utils.avatarContractAddresses) {
        const contractAddress = contractInfo.address

        // promises.push(getAvatar(contractAddress))
        const contract = new web3.eth.Contract(abi, contractAddress)

        // 所有数を確認
        const balance = await contract.methods.balanceOf(address).call()
        //console.log('Balance:', balance)
        
        for (let i=0; i<balance; i++) {
          // 所有しているトークンIDを取得
          const tokenId = await contract.methods.tokenOfOwnerByIndex(address, i).call()

          // トークンからtokenURIを取得
          const tokenUri = await contract.methods.tokenURI(tokenId).call()

          // メタデータ取得
          const metadataJson = await fetch(tokenUri, {
            mode: 'cors'
          })
          const metadata = await metadataJson.json()

          // メタデータにcontractAddressを追加
          metadata.contractAddress = contractAddress
          metadata.collectionId = contractInfo.collection

          this.avatars.push(metadata)
        }
      }

      this.isLoadingAvatars = false
    },

    async getItems(address) {
      const abi = metabaItemData.abi
      const web3 = web3Utils.getWeb3()

      this.isLoadingItems = true
      
      this.items = []

      for(let itemInfo of web3Utils.itemContracts) {
        const contract = new web3.eth.Contract(abi, itemInfo.address)

        // アイテムの全IDをAPIから取得
        const itemsData = await fetch(`https://api.metabaworld.com/v1/collections/${itemInfo.collection}/items/all`, {
          mode: 'cors'
        })
        const items = await itemsData.json()
        const itemIds = items.map((item)=>item.id)

        if (itemIds && itemIds.length > 0) {
          // idと同じ数の同一アドレスのarrayを生成
          const addresses = Array(itemIds.length).fill(address)

          // 所有数を確認
          const balances = await contract.methods.balanceOfBatch(addresses, itemIds).call()
          // console.log('Balance:', balances, itemIds)
          
          for (let i=0; i<itemIds.length; i++) {
            if (balances[i] > 0) {
              // トークンからtokenURIを取得
              const tokenUri = await contract.methods.uri(itemIds[i]).call()

              // メタデータ取得
              const metadataJson = await fetch(tokenUri, {
                mode: 'cors'
              })
              const metadata = await metadataJson.json()

              // メタデータにコントラクトアドレスとトークンIDを追加
              metadata.contractAddress = itemInfo.address
              metadata.tokenId = String(itemIds[i])

              this.items.push(metadata)
            }
          }
        }
      }

      this.isLoadingItems = false
    },

    async generateAvatarData(avatar) {
      this.selectedAvatarItems = []

      if (avatar && avatar.metaba && avatar.metaba.id && avatar.metaba.type === 'Head') {
        // アバターを表すHeadをセットする
        this.avatarData = {
          Head: {
            PrefabId: avatar.metaba.id,
          }
        }

        // アイテムのデフォルト-1をセットする
        for (const itemType of utils.getItemTypes()) {
          this.avatarData[itemType] = {
            PrefabId: "-1"
          }
        }

        const avatarAbi = metabaAvatarData.abi
        const itemAbi = metabaItemData.abi
        const web3 = web3Utils.getWeb3()
        const avatarContract = new web3.eth.Contract(avatarAbi, avatar.contractAddress)

        // アイテムのコントラクトアドレス集を確認
        const itemContracts = await avatarContract.methods.childContractsFor(String(avatar.id)).call()
        // console.log('itemContracts:', itemContracts)

        for(const itemContractAddress of itemContracts) {
          // アバターに紐づく該当アイテムコントラクトのtoken IDを取得
          const tokenIds = await avatarContract.methods.childTokenIdsForOn(String(avatar.id), itemContractAddress).call()

          if (tokenIds && tokenIds.length > 0) {
            // 描画用にとりあえずアイテムのデータを作成
            const items = Array(tokenIds.length).fill({image: null})

            // アイテムコントラクト生成
            const itemContract = new web3.eth.Contract(itemAbi, itemContractAddress)

            // コレクションIDを取得
            //const tokenUri = await itemContract.methods.uri(0).call()
            //const matches = tokenUri.match(/.*\/collections\/(.*)\/items*/)
            //console.log(matches['1'])

            // idと同じ数の同一アドレスのarrayを生成
            const addresses = Array(tokenIds.length).fill(avatar.contractAddress)

            // 所有数を確認
            const balances = await itemContract.methods.balanceOfBatch(addresses, tokenIds).call()
            // console.log('Balances:', balances, itemIds)
            
            for (let i=0; i<tokenIds.length; i++) {
              if (balances[i] > 0) {
                // トークンからtokenURIを取得
                const tokenUri = await itemContract.methods.uri(tokenIds[i]).call()

                // メタデータ取得
                const metadataJson = await fetch(tokenUri, {
                  mode: 'cors'
                })
                const metadata = await metadataJson.json()

                // アバターデータをピックアップ
                this.avatarData[metadata.metaba.type] = {
                  PrefabId: metadata.metaba.id,
                  TokenId: tokenIds[i],
                  ContractAddress: itemContractAddress
                }

                // 選択したアバターのアイテム情報を追加
                metadata.contractAddress = itemContractAddress
                metadata.tokenId = tokenIds[i]
                items[i] = metadata
              }
            }

            this.selectedAvatarItems = this.selectedAvatarItems.concat(items)
          }
        }
      }
      
      return null
    },

    async generateItemData() {
      // すべてをカテゴリごとに分けていく
      this.itemData = {}

      for (const itemType of utils.getItemTypes()) {
        const items = []

        // アイテム選択画面では選択されているのを一番手前にもってきたいので、まず最初にアバターをチェック
        if (this.avatarData && this.avatarData[itemType]) {
          if (this.avatarData[itemType].PrefabId !== "-1") {
            items.push({
              PrefabId: this.avatarData[itemType].PrefabId,
              Selected: true,
              TokenId: this.avatarData[itemType].TokenId,
              ContractAddress: this.avatarData[itemType].ContractAddress
            })
          }
        }

        // 次にアイテムからチェック
        for (const item of this.items) {
          if (item && item.metaba && itemType === item.metaba.type && item.metaba.id >= 0) {
            items.push({
              PrefabId: item.metaba.id,
              Selected: false,
              TokenId: item.tokenId,
              ContractAddress: item.contractAddress
            })
          }
        }

        this.itemData[itemType] = items
      }
    },

    makeTransactionData(afterAvatarData) {
      const beforeAvatarData = this.avatarData
      const removeTransactionData = []
      const addTransactionData = []

      for (const type of utils.getItemTypes()) {
        const bi = beforeAvatarData[type]
        const ai = afterAvatarData[type]

        // console.log(bi, ai)

        const hasDifference = function (before, after) {
          for (const key of ['PrefabId', 'TokenId', 'ContractAddress']) {
            let bv = before[key]
            let av = after[key]

            if (bv === void 0 || bv === '') {
              bv = '-1'
            }
            if (av === void 0 || av === '') {
              av = '-1'
            }

            if (bv !== av) {
              return true
            }
          }
          return false
        }

        if (hasDifference(bi, ai)) {
          // 変更されてる
          if (ai.PrefabId === '-1') {
            // biが取り外されただけ
            removeTransactionData.push({
              contractAddress: bi.ContractAddress,
              tokenId: bi.TokenId
            })
          } 
          else if (bi.PrefabId === '-1') {
            // aiが取り付けられただけ
            addTransactionData.push({
              contractAddress: ai.ContractAddress,
              tokenId: ai.TokenId
            })
          }
          else {
            // biが外されてaiが取り付けられた
            removeTransactionData.push({
              contractAddress: bi.ContractAddress,
              tokenId: bi.TokenId
            })
            addTransactionData.push({
              contractAddress: ai.ContractAddress,
              tokenId: ai.TokenId
            })
          }
        }
      }

      // console.log('removeTransactionData', removeTransactionData)
      // console.log('addTransactionData', addTransactionData)

      // トランザクション構築
      this.transactions = []
      this.transactionIndex = 0
      let transactionId = 0

      // remove系トランザクション。先に消してから追加しないと変なことになるので
      // 同じコントラクトへ送られるものはまとめる
      const removeContracts = removeTransactionData.map((t) => t.contractAddress).filter((x, i, self) => self.indexOf(x) === i)
      for (const contractAddress of removeContracts) {
        const tokenIds = []
        const length = removeTransactionData.length
        for (let i = 0; i<length; i++) {
          if (removeTransactionData[i].contractAddress === contractAddress) {
            // 該当のコントラクトのトランザクションはまとめます
            tokenIds.push(removeTransactionData[i].tokenId)
          }
        }

        if (tokenIds.length == 1) {
          // ひとつだけ
          this.transactions.push({
            type: 'remove',
            transactionId: transactionId++,
            contractAddress: this.selectedAvatar.contractAddress,  // MetabaAvatar
            contractType: 'avatar',
            batch: false,
            fromTokenId: this.selectedAvatar.id,
            to: this.accountInfo.address,
            childContract: contractAddress,
            childTokenId: tokenIds[0],
            amount: 1,
            data: '0x'
          })
        }
        else if (tokenIds.length > 1) {
          // 複数あるのでbatchにする
          this.transactions.push({
            type: 'remove',
            transactionId: transactionId++,
            contractAddress: this.selectedAvatar.contractAddress,  // MetabaAvatar
            contractType: 'avatar',
            batch: true,
            fromTokenId: this.selectedAvatar.id,
            to: this.accountInfo.address,
            childContract: contractAddress,
            childTokenIds: tokenIds,
            amounts: Array(tokenIds.length).fill(1),
            data: '0x'
          })
        }
      }

      // add系トランザクション
      // 同じコントラクトへ送られるものはまとめる
      const addContracts = addTransactionData.map((t) => t.contractAddress).filter((x, i, self) => self.indexOf(x) === i)
      for (const contractAddress of addContracts) {
        const tokenIds = []
        const length = addTransactionData.length
        for (let i = 0; i<length; i++) {
          if (addTransactionData[i].contractAddress === contractAddress) {
            // 該当のコントラクトのトランザクションはまとめます
            tokenIds.push(addTransactionData[i].tokenId)
          }
        }

        if (tokenIds.length == 1) {
          // ひとつだけ
          this.transactions.push({
            type: 'add',
            transactionId: transactionId++,
            contractAddress: contractAddress,  // MetabaItem
            contractType: 'item',
            batch: false,
            from: this.accountInfo.address,
            to: this.selectedAvatar.contractAddress,
            id: tokenIds[0],
            amount: 1,
            data: this.selectedAvatar.id  // MetabaAvatarのtokenId
          })
        }
        else if (tokenIds.length > 1) {
          // 複数あるのでbatchにする
          this.transactions.push({
            type: 'add',
            transactionId: transactionId++,
            contractAddress: contractAddress,  // MetabaItem
            contractType: 'item',
            batch: true,
            from: this.accountInfo.address,
            to: this.selectedAvatar.contractAddress,
            ids: tokenIds,
            amounts: Array(tokenIds.length).fill(1),
            data: this.selectedAvatar.id  // MetabaAvatarのtokenId
          })
        }
      }
    },

    getImageUrlsFromTransaction(transaction) {
      if (transaction.batch) {
        const itemTokenIds = transaction.type === 'add' ? transaction.ids : transaction.childTokenIds
        const itemContractAddress = transaction.type === 'add' ? transaction.contractAddress : transaction.childContract
        
        const items = []
        for (let i=0; i<itemTokenIds.length; i++) {
          const item = this.getImageUrlFromTokenIdAndContractAddress(itemTokenIds[i], itemContractAddress)
          if (item) {
            items.push(item)
          }
        }

        return items
      }
      else {
        const itemTokenId = transaction.type === 'add' ? transaction.id : transaction.childTokenId
        const itemContractAddress = transaction.type === 'add' ? transaction.contractAddress : transaction.childContract
        const item = this.getImageUrlFromTokenIdAndContractAddress(itemTokenId, itemContractAddress)

        if (item) {
          return [item]
        } else {
          return []
        }
      }
    },

    getImageUrlFromTokenIdAndContractAddress(tokenId, contractAddress) {
      const items = this.items.concat(this.selectedAvatarItems)
      // console.log('######', items)

      const result = items.filter((i) => {
        return i.tokenId === tokenId && i.contractAddress === contractAddress
      })

      if (result && result.length > 0) {
        return result[0]
      } else {
        return null
      }
    },

    async onClickSendTransaction(transaction, index) {
      // すでに送信していたらやめる
      if (transaction.loading || transaction.done || this.transactionIndex !== index) {
        return
      }

      // TODO: ちゃんとトランザクションが完了したら?
      if (this.isShowUnity) {
        this.isShowUnity = false
      }

      // ボタンをローディングにする
      transaction.loading = true
      this.$set(this.transactions, index, transaction)

      const abi = transaction.contractType === 'avatar' ? metabaAvatarData.abi : metabaItemData.abi
      const web3 = web3Utils.getWeb3()

      const contract = new web3.eth.Contract(abi, transaction.contractAddress, {
        from: this.accountInfo.address,
        gasPrice: 50000000000  // 30GWEI。30GWEIで問題なさそうだけど念の為50GWEIにしたほうが良いか？
      })

      let receipt = null

      try {
        if (transaction.type === 'add') {
          // add処理 (MetabaItem)
          if (transaction.batch) {
            // MetabaItem.safeBatchTransferFrom
            // const estimatedGasAmount = await contract.methods.safeBatchTransferFrom(
            //   transaction.from,
            //   transaction.to,
            //   transaction.ids,
            //   transaction.amounts,
            //   web3.eth.abi.encodeParameter('uint256', transaction.data)
            // ).estimateGas()
            receipt = await contract.methods.safeBatchTransferFrom(
              transaction.from,
              transaction.to,
              transaction.ids,
              transaction.amounts,
              web3.eth.abi.encodeParameter('uint256', transaction.data)
            // ).send({gas: estimatedGasAmount})
            ).send()
          }
          else {
            // MetabaItem.safeTransferFrom
            // const estimatedGasAmount = await contract.methods.safeTransferFrom(
            //   transaction.from,
            //   transaction.to,
            //   transaction.id,
            //   transaction.amount,
            //   web3.eth.abi.encodeParameter('uint256', transaction.data)
            // ).estimateGas()
            receipt = await contract.methods.safeTransferFrom(
              transaction.from,
              transaction.to,
              transaction.id,
              transaction.amount,
              web3.eth.abi.encodeParameter('uint256', transaction.data)
            // ).send({gas: estimatedGasAmount})
            ).send()
          }
        }
        else {
          // remove処理 (MetabaAvatar)
          if (transaction.batch) {
            // MetabaAvatar.safeBatchTransferChildFrom
            // const estimatedGasAmount = await contract.methods.safeBatchTransferChildFrom(
            //   transaction.fromTokenId,
            //   transaction.to,
            //   transaction.childContract,
            //   transaction.childTokenIds,
            //   transaction.amounts,
            //   transaction.data
            // ).estimateGas()
            receipt = await contract.methods.safeBatchTransferChildFrom(
              transaction.fromTokenId,
              transaction.to,
              transaction.childContract,
              transaction.childTokenIds,
              transaction.amounts,
              transaction.data
            // ).send({gas: estimatedGasAmount})
            ).send()
          } else {
            // MetabaAvatar.safeTransferChildFrom
            // const estimatedGasAmount = await contract.methods.safeTransferChildFrom(
            //   transaction.fromTokenId,
            //   transaction.to,
            //   transaction.childContract,
            //   transaction.childTokenId,
            //   transaction.amount,
            //   transaction.data
            // ).estimateGas()
            receipt = await contract.methods.safeTransferChildFrom(
              transaction.fromTokenId,
              transaction.to,
              transaction.childContract,
              transaction.childTokenId,
              transaction.amount,
              transaction.data
            // ).send({gas: estimatedGasAmount})
            ).send()
          }
        }

        // console.log('###', receipt)
        transaction.done = true
        this.$set(this.transactions, index, transaction)

        this.transactionHash = receipt.transactionHash
        this.isShowTransactionSuccess = true

        // お金の減り具合を確認
        setTimeout(() => {
          this.$emit('get-account-info')
        }, 2000)

        this.transactionIndex += 1
      } catch (e) {
        console.log(e)
        if (e && e.code === 4001) {
          // 無視する
        } else {
          this.isShowTransactionError = true
        }
      }      

      transaction.loading = false
      this.$set(this.transactions, index, transaction)
    },

    showTransactions() {
      // console.log('showTransactions', this.transactions)
      this.transactionDialog = true
    },

    convertUrlLink(text) {
      return utils.autoLink(text.replace(/\n\n/g, '\n'))
    },

    onClickReload() {
      window.location.reload()
    },

    onClickTxDetail() {
      window.open('https://polygonscan.com/tx/' + this.transactionHash, '_blank')
    },

    onClickCloseTransactionDialog() {
      this.transactionDialog = false
      this.loadAvatarsAndItems()
    },

    getMd5(text) {
      return md5(text)
    },

    getVideoName(specialVideoPath) {
      return this.getMd5('pinkBear' + specialVideoPath)
    },

    onClickPlaySpecialVideo() {
      const videoFileName = this.getVideoName(this.selectedAvatar && this.selectedAvatar.metaba && this.selectedAvatar.metaba.specialVideoPath)
      this.videoUrl = `https://contents.metabaworld.com/${this.selectedAvatar.collectionId}/avatars/${this.selectedAvatar.id}/${videoFileName}.mp4`
      // console.log(this.videoUrl)

      this.videoDialog = true

      // console.log(this.getMd5('haiku' + 'njpw'))
      // console.log(this.getMd5('haiku' + 'njpw2'))
      // console.log(this.getMd5('haiku' + 'njpw3'))
      // console.log(this.getMd5('haiku' + 'njpw4'))

      setTimeout(() => {
        if (this.$refs.videoContent) {
          this.$refs.videoContent.play()
        }
      }, 100)
    },

    stopVideo() {
      if (this.$refs.videoContent) {
        this.$refs.videoContent.pause()
      }

      this.videoDialog = false
    }
  },

  components: {
    UnityPlayer
  }
}
</script>

<style lang="scss" scoped>
  #grid {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(/images/grid/grid-background.jpg);
    background-size: contain;
    color: #FFF;
    margin: 0;
    padding: 0;
  }

  #connect-button {
    position: fixed;
    top: 12px;
    right: 15px;
    z-index: 200;
    text-transform: none !important;
  }

  #logo {
    position: fixed;
    top: 10px;
    left: 15px;
    width: 120px;
    height: 30px;
    z-index: 300;
  }

  .account {
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.5);
    width: 110px;
    height: 37px;
    font-size: 11px;
    border-radius: 5px;
    line-height: 12px;
    padding: 5px 10px;
    text-align: left;
    cursor: pointer;

    .account-address {
      font-size: 12px;
      margin-bottom: 5px;
      width: 95px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
    }

    .account-copy {
      position: absolute;
      bottom: -40px;
      right: 0;
      background: rgba(86, 171, 96, 0.9);
      padding: 10px;
      width: 180px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .main {
    padding: 80px 15px 280px 15px;
  }

  .avatar-description {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    font-size: 12px;
  }

  .updating-vrm {
    padding: 3px;
    font-size: 10px;
    font-weight: bold;
    background-color: rgba(102, 102, 102, 0.9);
    color: #FFF;
    margin-left: 5px;
  }

  .avatar-warning {
    margin-bottom: 15px;
    font-size: 10px;
  }

  .avatar-updating {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #FFF;
    font-weight: bold;

    .avatar-updating-progress {
      margin-left: 10px;
    }
  }
</style>
