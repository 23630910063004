import Web3 from 'web3'

export default {
  polygonMainnetChainIdString: '137',
  polygonMainnetChainIdHexString: '0x89',
  avatarContractAddresses: [
    {
      collection: 'horiemon',
      address: '0x2ad0d8e080E3B860CF28E0142401903226122149'
    },
    {
      collection: 'dragonzakura',
      address: '0xA289BCE3329dD3FA646fc4dCb15e7EB19c8B7594'
    },
    {
      collection: 'seamo',
      address: '0x4d5Ca51A27FC83D9c87c909059B0585A5974ec2F'
    },
    {
      collection: 'aiotsuka',
      address: '0x78Fd90Cf190Fae0CaD27E6BddF1440cE7f6363d7'
    },
    {
      collection: 'kishidanami',
      address: '0x3a2b3dF0089Ad8BA4F1DF2EA4D50e81862190D1b'
    },
    {
      collection: 'sady',
      address: '0x26b79e51c519Dd9B0A18b9293AfaFd6D7C699a43'
    },
    {
      collection: 'starter-boy',
      address: '0xc584D3ffA83C87eac3EC7cfEE582CC2ffF18611b'
    },
    {
      collection: 'starter-girl',
      address: '0x1565d6D63d863B0BD2F56FEFB78286E43F75B394'
    },
    {
      collection: 'njpw',
      address: '0x7C803077e96fF7898Bb16BA60e7C5aC8029C5580'
    },
    {
      collection: 'njpw2',
      address: '0x5D1522B6C52d9A27fc632F019DcAe547C49E9f67'
    },
    {
      collection: 'njpw3',
      address: '0xFaF7F2FE9AB326A43691c062C94A80272fC07150'
    },
    {
      collection: 'njpw4',
      address: '0x8F9016935F105D9B2d2C0246C716F07CC16faF57'
    }
  ],
  itemContracts: [
    {
      collection: 'honeybear',
      address: '0x52f8944B57103632d49554f3BFedd56dC609dB4c'
    },
    {
      collection: 'annasui',
      address: '0x7163ba5E4f44365E250c42CFEee07C9A21650CD8'
    },
    {
      collection: 'sady',
      address: '0x6a984eB160e4796f17b7A8AcF451b2B0f1121370'
    },
    {
      collection: 'aiotsuka',
      address: '0xD423b10Bd05794ccCed1f32B69DFB253B3D08eEA'
    },
    {
      collection: 'dragonzakura',
      address: '0xfcc87150D55ceDCa720Ea0c03b72397a38331dfe'
    },
    {
      collection: 'horiemon',
      address: '0xBE4bA48489ce8FF1813332Ab438deCFE1b844312'
    },
    {
      collection: 'kishidanami',
      address: '0x9dB68eC9Bb1f20B09579e412f231a570ae14f964'
    },
    {
      collection: 'seamo',
      address: '0x1282fb5187E5615057Ab8251D367a043820971f0'
    },
    {
      collection: 'metaba',
      address: '0xC21FcAcd4315531638Cb7d3D747516FE1466cAE1'
    },
    {
      collection: 'njpw',
      address: '0x18578476BbFDb665075162062Ae2438833F07D5b'
    },
  ],

  getWeb3() {
    // Get web3 and enable ethereum
    try {
      let web3 = window.web3
      if (typeof window.ethereum !== 'undefined') {
        web3 = new Web3(window.ethereum)
        return web3
      } else if (typeof web3 !== 'undefined') {
        web3 = new Web3(web3.currentProvider)
        return web3
      } else {
        return null
      }
    } catch(e) {
      return null
    }
  },

  async getChainIdString() {
    try {
      const web3 = this.getWeb3()
      const chainIdString = await web3.eth.net.getId()
      return String(chainIdString).trim()
    } catch(e) {
      // retry
      setTimeout(async () =>{
        const web3 = this.getWeb3()
        const chainIdString = await web3.eth.net.getId()
        return String(chainIdString).trim()
      }, 300)
    }
  },

  isMetamask() {
    return !!(window.ethereum && window.ethereum.isMetaMask)
  },

}
