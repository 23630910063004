<template>
  <div class="unity">
    <div class="unity-progress-container" v-if="isShowLoadingDialog">
      <div class="unity-progress-text" v-html="unityLoadingText"></div>
      <div class="unity-progress-number">{{unityLoadingPercent}}</div>
    </div>

    <div ref="unityContainer" class="unity-container">
      <canvas class="unity-canvas" 
        ref="unityCanvas"
        :width="canvasWidth" :height="canvasHeight"
        :style="{width: unityCanvasWidth + 'px', height: unityCanvasHeight + 'px'}">
      </canvas>
      <v-btn
        color="white"
        style="position: absolute; bottom: -40px; left: calc(50% - 50px)"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
const baseDeployId = 'DEPLOY_ID'  // デプロイIDというキーワードはデプロイスクリプトで日付にリプレイスしています
const unityProjectName = 'dressup'
const buildUrl = '/unity/' + unityProjectName + '/Build'

export default {
  props: {
    unityMessage: Object,
    save: Function,
    cancel: Function,
    initialized: Function
  },

  data() {
    const deployId = baseDeployId.indexOf('DEPLOY') === 0 ? Math.floor(Math.random() * 1000) : baseDeployId

    return {
      canvasWidth: 400,
      canvasHeight: 580,
      unityCanvasWidth: 400,
      unityCanvasHeight: 580,
      isShowLoadingDialog: false,
      isLoadingUnity: false,
      loaderUrl: buildUrl + '/' + unityProjectName + '.loader.js?' + deployId,
      config: {
        dataUrl: buildUrl + '/' + unityProjectName + '.data?' + deployId,
        frameworkUrl: buildUrl + '/' + unityProjectName + '.framework.js?' + deployId,
        codeUrl: buildUrl + '/' + unityProjectName + '.wasm?' + deployId,
        streamingAssetsUrl: 'StreamingAssets',
        companyName: 'CORK, Inc.',
        productName: 'METABA',
        productVersion: '0.0.1',
      },
      unityLoadingPercent: '0 %',
      unityLoadingText: 'Loading...',
      unityInstance: null
    }
  },

  watch: {
    unityMessage(message) {
      if (this.unityInstance && message && message.gameObjectName && message.functionName && message.data) {
        // console.log('SendMessage', message)
        this.unityInstance.SendMessage(
          message.gameObjectName,
          message.functionName,
          JSON.stringify(message.data)
        )
      }
    }
  },

  mounted() {
    this.resizeCanvas()

    // Listen Unity event
    window.addEventListener('message', this.onUnityMessage, false)

    this.startUnity()
  },

  destroyed() {
    // Remove Unity event listener
    window.removeEventListener('message', this.onUnityMessage, false)
  },

  methods: {
    startUnity() {
      let isLoaded = false

      this.isLoadingUnity = true
      this.isShowLoadingDialog = true
      
      const script = document.createElement('script')
      script.src = this.loaderUrl
      script.onload = async () => {
        this.unityInstance = await createUnityInstance(this.$refs.unityCanvas, this.config, (progress) => {
          this.unityLoadingPercent = parseInt(progress * 100) + ' %'

          if (progress >= 1 && !isLoaded) {
            isLoaded = true

            this.unityLoadingText = 'Loaded!<br>Starting METABA Grid...'
          }
        })
      }
      document.body.appendChild(script)
    },

    resizeCanvas() {    
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight
      const screenRetio = screenWidth / screenHeight
      let height, width

      if (screenRetio > 1.0) {
        // pc height: max
        height = screenHeight
        width = height * this.canvasWidth / this.canvasHeight
      } else {
        // Smartphone width: max
        width = screenWidth
        height = width * this.canvasHeight / this.canvasWidth
      }

      this.unityCanvasWidth = width
      this.unityCanvasHeight = height
    },


    onUnityMessage(event) {
      try{
        const message = event && event.data

        // Check types
        if (typeof(message) !== 'string' && !(message instanceof String) || message == 'null') {
            return;
        }

        const parameterObject = JSON.parse(message)
        const methodName = parameterObject.MethodName
        const metabaCode = parameterObject.MetabaCode

        if (metabaCode !== 'GERORINPA') {
          return
        }

        // console.log(methodName, parameterObject)
        
        if (methodName === 'onClickSave') {
          this.$emit('save', parameterObject)
        }
        else if (methodName === 'onClickCancel') {
          this.$emit('cancel')
        }
        else if (methodName === 'initialized') {
          setTimeout(() => {
            this.isShowLoadingDialog = false
          }, 600)
          
          this.$emit('initialized')
        }
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .unity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;

    .unity-progress-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 13;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .unity-progress-text {
      font-size: 14px;
      font-weight: bold;
      margin: 20px 0 10px 0;
    }

    .unity-progress-number {
      font-size: 12px;
    }

    .unity-container {
      position: relative;
      margin: 0 auto;
      z-index: 12;
    }
  }
</style>
