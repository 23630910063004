<template>
  <header class="is-relative">
    <img class="top" src="/images/home/metaba-njpw2-top.png" alt="着せ替え可能なNFTアバターサービス">
  </header>
</template>

<script>
export default ({

})
</script>

<style lang="scss" scoped>
@use '../home.css';

.top {
  width: 100%;
}
</style>
