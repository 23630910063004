<template>
<div class="dialog-container" @click="onClickOther">
    <v-card
      class="install-dialog"
      max-width="344"
      outlined
      @click="onClickBody"
      :ripple="false"
    >
      <v-card-title>
        Select wallet
      </v-card-title>

      <v-card-actions>
        <v-img class="install-image" max-width="240" src="/images/grid/metamask.png" @click="onClickMetamask" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import utils from '../utils'

export default {
  props: ['onClose'],

  data() {
    return {
    }
  },

  mounted() {
  },

  methods: {
    onClickMetamask() {
      if (utils.isSmartphone()) {
        location.href = 'https://metamask.app.link/dapp/metabaworld.com/grid/inventory'
      } else {
        location.href = 'https://metamask.io/download.html'
      }
    },

    onClickOther() {
      if (this.onClose) {
        this.onClose()
      }
    },

    onClickBody(e) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .install-dialog {
    width: 300px;
  }

  .install-dialog-body {
    text-align: center;
  }

  .install-image {
    cursor: pointer;
  }
</style>
