<template>
  <div class="main-body">
    <h1 id="njpw">{{$t('collectionBody.titleNjpw')}}</h1>

    <v-container>
      <v-row
        class="mb-6"
      >
        <v-col
          v-for="collection in collectionsNjpw"
          :key="collection.id"
          cols="12"
          sm="4"
        >
          <v-card>
              <v-img
                :src="'/images/collections/' + collection.id + '.jpg'"
                :aspect-ratio="1"
              >
              </v-img>

              <v-card-actions>
                {{collection.title[$i18n.locale]}}

                <v-spacer></v-spacer>

                <v-btn
                  class="opensea-button"
                  :href="'https://opensea.io/collection/metaba-' + collection.id"
                  target="_blank"
                >
                  Open Sea
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-col>
      </v-row>
    </v-container>

    <h1 id="1st">{{$t('collectionBody.title1st')}}</h1>

    <v-container>
      <v-row
        class="mb-6"
      >
        <v-col
          v-for="collection in collections1st"
          :key="collection.id"
          cols="12"
          sm="4"
        >
          <v-card>
              <v-img
                :src="'/images/collections/' + collection.id + '.jpg'"
                :aspect-ratio="1"
              >
              </v-img>

              <v-card-actions>
                {{collection.title[$i18n.locale]}}

                <v-spacer></v-spacer>

                <v-btn
                  class="opensea-button"
                  :href="'https://opensea.io/collection/metaba-' + collection.id"
                  target="_blank"
                >
                  Open Sea
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      collectionsNjpw: [
        { title: {ja: '新日本プロレス', en: 'NJPW'}, id: 'njpw'},
        { title: {ja: '新日本プロレス Tシャツ', en: 'NJPW T-Shirt'}, id: 'njpw-items'},
      ],
      collections1st: [
        { title: {ja: '大塚愛', en: 'Ai Otsuka'}, id: 'aiotsuka'},
        { title: {ja: 'SEAMO', en: 'SEAMO'}, id: 'seamo'},
        { title: {ja: 'ドラゴン桜', en: 'Dragon Zakura'}, id: 'dragonzakura'},
        { title: {ja: 'ホリエモン', en: 'Horiemon'}, id: 'horiemon'},
        { title: {ja: '岸田奈美', en: 'Nami Kishida'}, id: 'kishidanami'},
        { title: {ja: 'ハニーベア', en: 'Honeybear'}, id: 'honeybear'},
        { title: {ja: 'ANNA SUI', en: 'ANNA SUI'}, id: 'annasui'},
      ],
    }),
  }
</script>

<style lang="scss" scoped>
.main-body {
  padding-top: 40px;
}

.opensea-button {
  background-color: #1C69DB !important;
  color: #FFF !important;
}
</style>
