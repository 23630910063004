import Vue from 'vue'
import Vuex from 'vuex'

import Web3 from 'web3'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    web3: {}
  },
  getters: {
    getWeb3: (state) => {
      return state.web3
    }
  },
  mutations: {
    async mutateWeb3Provider(state) {
      if (window.ethereum) {
        await window.ethereum.request({method: 'eth_requestAccounts'})
        const web3 = new Web3(window.ethereum)
        state.web3 = web3
        return true
      }
      return false
    }
  },
  actions: {
    async setWeb3Provider({ commit }) {
      await commit('mutateWeb3Provider')
    }
  },
  modules: {
  }
})
