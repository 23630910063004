<template>
  <div class="main-body">
    <h2 class="top-title">{{$t('clawBody.title')}}</h2>
    <div style="margin: 20px 0">
      <div v-html="$t('clawBody.1.text')"></div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.2.title')}}</h4>
      <div>
        {{$t('clawBody.2.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.3.title')}}</h4>
      <div>
        {{$t('clawBody.3.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.4.title')}}</h4>
      <div>
        {{$t('clawBody.4.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.5.title')}}</h4>
      <div>
        {{$t('clawBody.5.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.6.title')}}</h4>
      <div>
        {{$t('clawBody.6.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.7.title')}}</h4>
      <div>
        {{$t('clawBody.7.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.8.title')}}</h4>
      <div>
        {{$t('clawBody.8.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.9.title')}}</h4>
      <div>
        {{$t('clawBody.9.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.10.title')}}</h4>
      <div>
        {{$t('clawBody.10.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.11.title')}}</h4>
      <div v-html="$t('clawBody.11.text')">
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.12.title')}}</h4>
      <div>
        {{$t('clawBody.12.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.13.title')}}</h4>
      <div>
        {{$t('clawBody.13.text')}}
      </div>
    </div>

    <div class="section">
      <h4>{{$t('clawBody.14.title')}}</h4>
      <div>
        {{$t('clawBody.14.beforeText')}}
        <router-link :to="{name: 'PrivacyPolicy'}">{{$t('privacyPolicy')}}</router-link>
        {{$t('clawBody.14.afterText')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.main-body {
  padding-top: 40px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  .section {
    margin: 20px 0;
  }

  .final-section {
    margin: 20px 0 100px 0;
  }

  ol.aol {
    li.aol {
      list-style-type: none;
      counter-increment: cnta;
      position: relative;
      margin-left: 5px;

      &:before {
        content: "(" counter(cnta, lower-alpha) ")";
        text-align: right;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }
  }

  ol.iol {
    li.iol {
      list-style-type: none;
      counter-increment: cnti;
      position: relative;
      margin-left: 10px;

      &:before {
        content: "(" counter(cnti, lower-roman) ")";
        text-align: right;
        position: absolute;
        top: 0;
        left: -30px;
      }
    }
  }
}
</style>
