<template>
  <div class="home">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap" rel="stylesheet">

    <Njpw2TopImage />

    <div class="wallet">
      <v-btn
          v-if="!accountInfo"
          depressed
          color="primary"
          @click="onClickConnectButton"
      >
        {{$t('connectToWallet')}}
      </v-btn>
      <v-btn
          v-else
          depressed
          color="primary"
      >
        <localized-link class="white--text" :to="{name: 'Grid'}">{{$t('goToGrid')}}</localized-link>
      </v-btn>

      <language-switcher class="lang-selector" v-slot="{ links }">
        <router-link class="flag-container" :to="link.url" v-for="link in links" :key="link.langIndex">
          <img class="flag" :src="'/images/lang/' + link.langIndex + '.png'" />
        </router-link>
      </language-switcher>
    </div>

    <main>
      <section class="area-join">

        <Njpw2TopBody />

        <div class="box-txt-attn">
          <h3 class="t-Center" v-if="$i18n.locale === 'ja'">
            <span class="c-vio f-56">着せ替え可能</span><span class="f-56">な</span><br>
            <span class="f-80">NFTアバター<br class="only-sp">サービス</span><br>
            <span class="f-80">「METABA」</span><br>
            <span class="f-56">さまざまなメタバースや<br>ユーティリティで使える<br>マルチユースアバターです。</span><br>
            <span class="f-56">全アイテム、</span><br class="only-sp"><span class="c-vio f-56">OpenSea</span><span class="f-56">で販売！</span>
          </h3>
          <h3 class="t-Center" style="font-size: 30px" v-else>
            Customizable NFT avatar service METABA is<br>
            "the multi-purpose avatars" that can be used<br>
            in different metaverses and utilities.<br>
            All items are sold on OpenSea!<br>
          </h3>
        </div>

        <div class="box-intro" style="margin-bottom: 80px; margin-top: 20px">
          <h3 style="font-size: 30px; margin-top: 50px;">{{$t('supportedServices')}}</h3>
          <ul>
            <li><a href="https://3tene.com/" target="_blank">3tene</a></li>
            <li><a href="https://store.steampowered.com/app/1364390/Animaze_by_FaceRig/" target="_blank">Animaze</a></li>
            <a href="https://cluster.mu/" target="_blank">cluster</a>
            <a href="https://digital-standard.com/tdpt_lp/" target="_blank">TDPT</a>
            <a href="https://sites.google.com/view/vdraw/" target="_blank">VDRAW</a>
            <a href="https://virtualcast.jp/" target="_blank">Virtual Cast</a>
            <a href="https://vmc.info/" target="_blank">VirtualMotionCapture</a>
            <a href="https://booth.pm/ja/items/2223918" target="_blank">VRM Automatic Photographing</a>
            <a href="https://denchisoft.com/" target="_blank">VTubeStudio</a>
          </ul>
        </div>

        <!--div class="box-intro" style="margin-bottom: 80px; margin-top: 20px">
          <div class="img-intro2">
            <a href="https://3tene.com/" target="_blank" class="utils-logo"><img src="/images/utils-logo/3tene.png" alt="3tene" class="utils-logo-image square"></a>
            <a href="https://cluster.mu/" target="_blank" class="utils-logo"><img src="/images/utils-logo/cluster.png" alt="cluster" class="utils-logo-image"></a>
            <a href="https://digital-standard.com/tdpt_lp/" target="_blank" class="utils-logo"><img src="/images/utils-logo/tdpt.png" alt="TDPT" class="utils-logo-image square"></a>
            <a href="https://store.steampowered.com/app/1364390/Animaze_by_FaceRig/" target="_blank" class="utils-logo"><img src="/images/utils-logo/animaze.png" alt="animaze" class="utils-logo-image"></a>
            <a href="https://booth.pm/ja/items/2223918" target="_blank" class="utils-logo"><img src="/images/utils-logo/vap.png" alt="VRM Automatic Photographing" class="utils-logo-image"></a>
            <a href="https://sites.google.com/view/vdraw/" target="_blank" class="utils-logo"><img src="/images/utils-logo/vdraw.png" alt="VDRAW" class="utils-logo-image"></a>
            <a href="https://denchisoft.com/" target="_blank" class="utils-logo"><img src="/images/utils-logo/vts.png" alt="VTubeStudio" class="utils-logo-image"></a>
            <a href="https://vmc.info/" target="_blank" class="utils-logo"><img src="/images/utils-logo/vmc.png" alt="VMC" class="utils-logo-image square"></a>
          </div>
        </div-->
      </section>
      <!-- /.area-join -->

      <section class="area-join" style="margin-bottom: 100px; margin-top: 100px;">
        <h3 class="f-56">{{$t('collections')}}</h3>

        <div style="margin: 0 auto; margin-top: 5px; max-width: 900px">
          <v-container>
            <v-row
            >
              <v-col
                cols="12"
                sm="6"
              >
                <router-link :to="{name: 'Collections', hash: '#1st'}">
                  <v-img src="/images/home/1st-collection2.png" style="margin: 20px"></v-img>
                </router-link>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <router-link :to="{name: 'Collections', hash: '#njpw'}">
                  <v-img src="/images/home/njpw-collection3.png" style="margin: 20px"></v-img>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>

      <section class="area-join" style="margin: 0 auto; margin-bottom: 50px; max-width: 800px">
        <router-link :to="{name: 'Mint'}">
          <v-img src="/images/home/free-mint-banner.png" contain></v-img>
        </router-link>
      </section>

      <section class="area-join" style="margin: 0 auto; margin-bottom: 150px; max-width: 800px">
        <v-img src="/images/home/metaba-map.png"></v-img>
      </section>

      <!--section class="area-join" style="margin-bottom: 150px;">
        <a :href="$i18n.locale === 'ja' ? 'https://twitter.com/njpw1972/status/1527121877130530817' : 'https://twitter.com/njpwglobal/status/1527121878011109378'" target="_blank">
          <img :src="'/images/home/tshirt_' + $i18n.locale + '.png'" class="tshirt">
        </a>
      </section-->
      <!-- /.area-join -->

      <!-- /.area-avatar -->

      <!-- <section class="area-join" style="margin: 0 0 100px 0; padding: 0">
        <div class="box-join-top">
          <span class="f-56">{{$t('homeBody.howtobuy')}}</span><br>
          <div class="youtube">
            <div class="frame-wrapper-video">
              <iframe src="https://www.youtube.com/embed/FXlQnk02bpo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section> -->

      <div style="margin: 0 auto; margin-top: 0; max-width: 900px">
        <v-container>
          <v-row
            class="mb-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <a href="https://discord.com/invite/gWukqQPQCN" target="_blank" class="discord">
                <v-img :src="'/images/home/discord_' + $i18n.locale + '.jpg'"  style="margin: 5px" />
              </a>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <a :href="$i18n.locale === 'ja' ? 'https://twitter.com/Metaba_World' : 'https://twitter.com/MetabaWorld'" target="_blank" class="discord">
                <v-img :src="'/images/home/twitter.png'" style="margin: 5px" />
              </a>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div style="margin-bottom: 100px"></div>

      <section class="area-project t-Center">
        <a class="link-top" href="#"><img src="/img/logo-top-01.png" alt="METABA"></a>
        <ul class="txt-member">
          <div class="ttl-project">
            <img src="/img/ttl-top-03.png" alt="Project Members">
          </div>
          <li><a href="https://www.shindokosuke.com" target="_blank">Kosuke Shindo</a></li>
          <li><a href="https://red20ne.eth.xyz/" target="_blank">red20ne.eth</a></li>
          <li>Rutile Misono</li>
          <li>Fusako</li>
          <li><a href="http://yasucom.html.xdomain.jp" target="_blank">Yasucom</a></li>
        </ul>
        <p class="txt-copylighting f-yugo t-Center" style="margin-bottom: 150px">Design by <a href="https://linkstory.co.jp/" target="_blank">LinkStory</a></p>
        <br>
      </section>
      <!-- /.area-project -->
    </main>
  </div>
</template>

<script>
// import NormalTopImage from '@/views/top/NormalTopImage'
// import NormalTopBody from '@/views/top/NormalTopBody'

import Njpw2TopImage from '@/views/top/Njpw2TopImage'
import Njpw2TopBody from '@/views/top/Njpw2TopBody'

export default ({
  components: {
    Njpw2TopImage,
    Njpw2TopBody
  },

  props: {
    accountInfo: Object,
    getAccountInfo: Function,
    connectWallet: Function,
  },

  data() {
    return {
      links: [
        {
          target: 'NftTos',
          i18nName: 'nftTos'
        }
      ]
    }
  },

  created() {
    //console.log('########', this.$i18n.locale)
  },

  methods: {
    onClickConnectButton() {
      // console.log(this.accountInfo)
      this.$emit('connect-wallet')
    },
  }
})
</script>

<style lang="scss" scoped>
  @use 'home.css';

  .wallet {
    position: fixed;
    right: 20px;
    top: 15px;
    z-index: 10;

    button {
      border-radius: 20px !important;
      font-weight: bold;
    }
  }

  .img-intro2 {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .utils-logo {
    width: 25%;
  }

  .utils-logo-image {

  }

  .utils-logo-image.square {
    width: 60%;
  }


  .lang-selector {
    margin: 15px;
    text-align: right;

    .flag-container {
      display: inline-block;
    }

    .flag {
      width: 30px;
      margin: 3px;
    }
  }

  main {
    padding-bottom: 100px;
  }

  .home main {
    padding-bottom: 0;
  }

  .top-banner {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .tshirt {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }

  .discord {
    //margin: 10px;
  }

  .youtube {
    margin: 0 auto;
    max-width: 800px;
  }

  .frame-wrapper-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .frame-wrapper-video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ul {
    a {
      font-size: 25px;
    }

    a:link {
      color: #FFF !important;
    }

    a:visited {
      color: #AAA !important;
    }
  }

</style>
