<template>
  <div class="mint">
    <v-img class="header" src="/images/mint/free-mint-banner.png" position="left center"/>

    <v-container class="choose-nft lighten-5">
      <template v-if="accountInfo">
        <v-row class="starter-avatars">
          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              class="mx-auto"
            >
              <v-img
                class="thumbnail align-end"
                :aspect-ratio="600/400"
                src="/images/mint/feature-girl.jpg"
              >
                <v-card-title class="nft-title">METABA Girl</v-card-title>
              </v-img>
              <v-card-actions>
                <div class="onperperson">
                  {{getStatus(isEnableGirlMint)}}
                </div>
                <v-spacer />
                <v-btn color="primary" @click="onClickMintGirl" :disabled="!isEnableGirlMint" :loading="isLoadingGirl">
                  Free Mint
                  <v-icon
                    right
                    dark
                  >
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              class="mx-auto"
            >
              <v-img
                class="thumbnail align-end"
                :aspect-ratio="600/400"
                src="/images/mint/feature-boy.jpg"
              >
                <v-card-title class="nft-title">METABA Boy</v-card-title>
              </v-img>
              <v-card-actions>
                <div class="onperperson">
                  {{getStatus(isEnableBoyMint)}}
                </div>
                <v-spacer />
                <v-btn color="primary" @click="onClickMintBoy" :disabled="!isEnableBoyMint" :loading="isLoadingBoy">
                  Free Mint
                  <v-icon
                    right
                    dark
                  >
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-btn style="margin-top: 50px" x-large rounded color="primary" @click="onClickShowAvatar">
          <!-- Show my avatars -->
          {{$t('mintBody.showAvatars')}}
        </v-btn>
      </template>

      <v-btn v-else style="margin-top: 20px" x-large rounded color="primary" @click="onClickConnectButton">
        <!-- Connect wallet -->
        {{$t('connectToWallet')}}
      </v-btn>
    </v-container>

    <v-snackbar
      v-model="isShowTransactionError"
      multi-line
      timeout="20000"
    >
      <h4>Failed transaction</h4>
      Please reload this page and retry again!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="onClickReload"
        >
          Reload
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="isShowTransactionSuccess"
      timeout="5000"
    >
      Transaction is successfully completed

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="onClickTxDetail"
        >
          Details
        </v-btn>
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="onClickShowAvatar"
        >
          Show Avatar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import web3Utils from '../web3utils'
import metabaStarterAvatarData from '../abis/MetabaStarterAvatar'

export default ({
  props: {
    accountInfo: Object,
    getAccountInfo: Function,
    connectWallet: Function,
  },

  data() {
    return {
      isEnableGirlMint: null,
      isEnableBoyMint: null,
      isLoadingGirl: false,
      isLoadingBoy: false,
      isShowTransactionError: false,
      isShowTransactionSuccess: false,
      transactionHash: null,
    }
  },

  methods: {
    getStatus(flag) {
      if (flag == null) {
        return 'Loading'
      } else if(flag) {
        return 'ひとり1回' //'One per Person'
      } else {
        return 'Mint済み' //'Minted'
      }
    },

    onClickMintGirl() {
      this.mintStarter('starter-girl')
    },

    onClickMintBoy() {
      this.mintStarter('starter-boy')
    },

    async getStarterAvatarBalances() {
      const abi = metabaStarterAvatarData.abi
      const web3 = web3Utils.getWeb3()

      for (const collectionId of ['starter-girl'  , 'starter-boy']) {
        const contractInfo = web3Utils.avatarContractAddresses.find((data) => {
          return data.collection === collectionId
        })
        
        const contractAddress = contractInfo.address
        const contract = new web3.eth.Contract(abi, contractAddress)

        // 所有数を確認
        const balance = await contract.methods.balanceOf(this.accountInfo.address).call()

        if (collectionId === 'starter-girl') {
          this.isEnableGirlMint = Number(balance) === 0
        } else {
          this.isEnableBoyMint = Number(balance) === 0
        }
      }
    },

    async mintStarter(collectionId) {
      try {
        const abi = metabaStarterAvatarData.abi
        const web3 = web3Utils.getWeb3()

        const contractInfo = web3Utils.avatarContractAddresses.find((data) => {
          return data.collection === collectionId
        })

        const contractAddress = contractInfo.address
        const contract = new web3.eth.Contract(abi, contractAddress)

        if (collectionId === 'starter-girl') {
          this.isLoadingGirl = true
        } else {
          this.isLoadingBoy = true
        }

        // const estimatedGasAmount = await contract.methods.safeMint(this.accountInfo.address).estimateGas()

        // Wait for safeMint
        // const receipt = await contract.methods.safeMint(this.accountInfo.address).send({gas: estimatedGasAmount, from: this.accountInfo.address})
        const receipt = await contract.methods.safeMint(this.accountInfo.address).send({from: this.accountInfo.address})
        this.transactionHash = receipt.transactionHash

        // console.log('SUCESS?')

        // Success
        if (collectionId === 'starter-girl') {
          this.isLoadingGirl = false
          this.isEnableGirlMint = false
          // console.log('SUCESS? girl')
        } else {
          this.isLoadingBoy = false
          this.isEnableBoyMint = false
          // console.log('SUCESS? boy')
        }

        // setTimeout(() => {
        //   this.getStarterAvatarBalances()
        // }, 5000)

        // console.log(receipt)

      } catch (e) {
        console.log('mintStarter ERROR', e)

        this.isShowTransactionError = true
        if (collectionId === 'starter-girl') {
          this.isLoadingGirl = false
        } else {
          this.isLoadingBoy = false
        }
      }
    },

    onClickReload() {
      window.location.reload()
    },

    onClickTxDetail() {
      window.open('https://polygonscan.com/tx/' + this.transactionHash, '_blank')
    },

    onClickShowAvatar() {
      this.isShowTransactionSuccess = false
      this.$router.push({name: 'Inventory'})
    },

    onClickConnectButton() {
      console.log(this.accountInfo)
      this.$emit('connect-wallet')
    }
  },

  mounted() {
    if (this.accountInfo) {
      this.getStarterAvatarBalances()
    }
  },

  watch: {
    accountInfo(val) {
      if (val && val.address) {        
        this.getStarterAvatarBalances()
      }
    }
  },
})
</script>

<style lang="scss">
  .mint {
    height: 100%;
    width: 100%;
    background: #050710;
    margin-bottom: 300px;
  }

  .header {
    min-height: 150px;
    max-height: 500px;
    width: 100%;
    background: #080C1A;
    background-size: auto 100%;
  }

  .nft-title {
    color: #666;
    font-weight: bold;
  }

  .nft-subtitle {
    text-align: left;
  }

  .choose-nft {
    margin-top: 30px;
  }

  .onperperson {
    margin-left: 10px;
  }

  .starter-avatars {
    max-width: 1024px;
    margin: 0 auto;
  }
</style>
