<template>
  <div class="container" style="margin: 0 auto; margin-top: 80px; max-width: 1200px">
    <a href="https://mini.metabaworld.com/njpw2" class="metaba-mini-text">
      通常版・新日本プロレス公式METABAアバター（Standard Version）<br/>
      棚橋弘至/高橋ヒロム/エル・デスペラード/内藤哲也
    </a>
    <v-container>
      <v-row dense>
        <v-col cols="6" sm="3">
          <v-img class="grey lighten-2" src="/images/njpw2/thumb0.png" aspect-ratio="1"></v-img>
        </v-col>
        <v-col cols="6" sm="3">
          <v-img class="grey lighten-2" src="/images/njpw2/thumb1.png" aspect-ratio="1"></v-img>
        </v-col>
        <v-col cols="6" sm="3">
          <v-img src="/images/njpw2/thumb2.png" aspect-ratio="1"></v-img>
        </v-col>
        <v-col cols="6" sm="3">
          <v-img src="/images/njpw2/thumb3.png" aspect-ratio="1"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <div class="metaba-mini-button-container">
      <div class="metaba-mini-text">
        特設ページはこちら
      </div>
      <div class="metaba-mini-button">
        <a href="https://mini.metabaworld.com/njpw2" target="_blank">
          <v-img class="metaba-mini-button-image" width="300" contain src="/images/njpw2/metaba-mini-logo.png"></v-img>
        </a>
      </div>

      <!--a class="metaba-mini-button" href="https://opensea.io/collection/metaba-njpw2" target="_blank">
        <v-img contain src="/images/njpw2/opensea.png"></v-img>
      </a-->
    </div>

    <div class="compare-container">
      <h3 class="compare-title">Deluxe Version (第1弾) と Standard Version (第2弾) の違い</h3>
      <v-container>
        <v-row dense class="mb-6">
          <v-col cols="12" sm="6">
            <v-card>
              <div class="top-contents">
                <video class="deluxe-video" src="/images/home/Tanahashi_TurnTableOS2.mp4" poster="/images/home/tanahashi-avatar.png" controls />
              </div>
              <v-card-title>
                Deluxe Version (第1弾) / 2022年7月1日販売終了
              </v-card-title>
              <v-card-text>
                <div class="compare-text">
                  ・選手アバターNFT（サイン入り）<br/>
                  公式HPより、各種メタバース、ユーティリティで利用可能なアバターデータ（VRM）をダウンロードいただけます。<br/>
                  ・選手からのオリジナルメッセージ動画<br/>
                  <v-img class="deluxe-image" src="/images/home/tanahashi-message.png" width="150" contain></v-img>
                  ・初回オーナー特典<br/>
                  直筆サイン入りメタルアートプレート<br/>
                  （サイズ：15.5cm×21.13㎝)
                  <v-img class="deluxe-image" src="/images/home/tanahashi-card.png" width="120" contain></v-img>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <div class="compare-body-title">コレクション一覧はこちら</div>
              <v-card-text>
                <div class="metaba-mini-button">
                  <a href="https://opensea.io/collection/metaba-njpw" target="_blank">
                    <v-img class="metaba-mini-button-image" width="300" contain src="/images/njpw2/opensea.png"></v-img>
                  </a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card>
              <v-img
                  class="top-contents"
                  height="300"
                  src="/images/njpw2/thumb0.png"
                  contain
              ></v-img>
              <v-card-title>
                Standard Version (第2弾) / <span style="font-weight: bold; color: #FF1943">2022年10月13日21時販売開始</span>
              </v-card-title>
              <v-card-text>
                <div class="compare-text">
                  ・選手アバターNFT<br/>
                  公式HPより、各種メタバース、ユーティリティで利用可能なアバターデータ（VRM）をダウンロードいただけます。
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <div class="compare-body-title">特設ページはこちら</div>
              <v-card-text>
                <div class="metaba-mini-button">
                  <a href="https://mini.metabaworld.com/njpw2" target="_blank">
                    <v-img class="metaba-mini-button-image" width="300" contain src="/images/njpw2/metaba-mini-logo.png"></v-img>
                  </a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default ({

})
</script>

<style lang="scss" scoped>
.opensea {
  margin: 0 auto;
  max-width: 600px;
}

.press {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: left;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.metaba-mini-text {
  font-size: 25px;
  margin: 10px;
}

.container {
  font-weight: bold !important;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  a {
    text-decoration: underline !important;
  }

  a:visited {
    color: #EEE !important;
  }
}

.metaba-mini-button-container {
  margin: 0;
}

.metaba-mini-button {
  width: 330px;
  background-color: #FFF;
  margin: 0 auto;
}

.metaba-mini-button-image {
  margin: 0 auto;
  padding: 10px;
}

.top-contents {
  height: 300px;
  background-color: #333;
  padding: 0;
}

.compare-container {
  margin-top: 80px;

  .compare-title {
    font-weight: bold;
    margin: 10px;
  }

  .compare-text {
    text-align: left;
  }

  .compare-body-title {
    margin-top: 20px;
  }
}

.deluxe-video {
  height: 300px;
  margin: 0;
  padding: 0;
}

.deluxe-image {
  margin: 10px;
}
</style>
